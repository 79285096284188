<template>
  <!-- 登录表单 -->
  <div class="wx-login-form text-center" v-show="visible">
    <h2 class="T1B mg-t-8">微信扫码登录</h2>

    <div class="wx-qrcode" v-show="!isError" v-loading="loading">
      <div class="wx-qrcode_wrapper">
        <iframe :class="{ 'is-hide': !hasRead }" :src="qrcodeUrl" frameborder="0"></iframe>
      </div>
      <p class="mt-32px mb-52px T4">打开微信扫一扫</p>
    </div>

    <div class="wx-qrcode wx-qrcode-err" v-show="isError">
      <div class="wx-qrcode_wrapper pt-12px">
        <div class="qrcode-err_message" @click="updateQrcode">
          <i class="iconfont icon-shuaxin text-32"></i>
          <p class="D4">刷新二维码</p>
        </div>
      </div>
      <p class="mt-40px mb-44px T4 text-A3">二维码加载失败</p>
    </div>

    <div>
      <el-popover
        placement="top"
        content="请阅读并勾选下方的协议"
        width="auto"
        trigger="manual"
        popper-class="is-dark"
        v-model="popoverVisible"
      >
        <el-checkbox slot="reference" v-model="hasRead" />
      </el-popover>
      <span class="ml-4px cursor-pointer" @click="hasRead = !hasRead">我已阅读并同意</span>
      <span class="text-theme cursor-pointer hover:opacity-80" @click="$emit('read')">《用户协议及隐私政策》</span>
    </div>
  </div>
</template>

<script>
/**
 * 账号登录表单
 */
import { Button, Checkbox, Popover } from 'element-ui';
// api
import { getQrConnectCloud } from '@/api/common';
import { getHostUrlPath } from '@/utils/common';

export default {
  components: {
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Popover.name]: Popover,
  },
  props: {
    visible: Boolean,
  },
  data() {
    return {
      qrcodeUrl: '',
      loading: false,
      isError: false,

      popoverVisible: false,
      hasRead: true,
    };
  },
  watch: {
    visible(val) {
      this.popoverVisible = val && !this.hasRead;
    },
    hasRead(val) {
      this.popoverVisible = !val;
    },
  },
  methods: {
    initQrCode() {
      if (this.qrcodeUrl) return;
      this.isError = false;
      this.loading = true;

      getQrConnectCloud({
        returnType: 1,
        deviceType: 3,
        productType: 1,
        schoolHost: getHostUrlPath('/sync-wechat', '/manage'),
        cloudHost: encodeURIComponent(getHostUrlPath('/sync-wechat')),
      })
        .then((res) => {
          this.qrcodeUrl = res.result;
        })
        .finally(() => {
          setTimeout(() => {
            if (!this.qrcodeUrl) this.isError = true;
            this.loading = false;
          }, 500);
        });
    },

    updateQrcode() {
      this.qrcodeUrl = '';
      this.initQrCode();
    },
  },

  mounted() {
    this.initQrCode();
  },
};
</script>

<style lang="scss" scoped>
.wx-qrcode {
  width: 232px;
  margin: 58px auto 0;

  .wx-qrcode_wrapper {
    width: 100%;
    height: 232px;
    background: url('~@images/components/login/code_border.png') no-repeat;
    iframe {
      @apply w-full h-full;
    }
    .is-hide {
      filter: blur(12px);
    }
  }

  .qrcode-err_message {
    @apply flex-center flex-col mx-auto rounded-8px opacity-70 text-A10 cursor-pointer;
    width: 208px;
    height: 208px;
    background: #000;
    &:hover {
      opacity: 0.68;
    }
  }
}
</style>
