<template>
  <div class="account-login text-center">
    <h2 class="T1B mg-t-28 mg-b-40">选择学校</h2>
    <schoolSelect class="w-full mg-t-40" :defaultSchool="schoolInfo" @select="selectSchool" />
    <el-button class="w-full login-button" type="primary" @click="handleLogin">进入登录</el-button>
    <otherPlatform v-if="false" />
  </div>
</template>

<script>
import { Button } from 'element-ui';
import schoolSelect from '@/components/schoolSelect/index';
import otherPlatform from '@/views/login-school/components/otherPlatform/index';
import { mapState } from 'vuex';
// utils
import { getHostUrlPath } from '@/utils/common';

export default {
  components: {
    [Button.name]: Button,
    schoolSelect,
    otherPlatform,
  },
  computed: {
    ...mapState({
      schoolInfo: (state) => state.login.schoolInfo,
    }),
  },
  methods: {
    // 选择学校
    selectSchool(school) {
      // 存储所学学校信息
      this.$store.commit('login/setSchoolInfo', school);
      localStorage.setItem('zhkt_school', JSON.stringify(school));
    },

    handleLogin() {
      const schoolInfoStr = encodeURIComponent(JSON.stringify(this.schoolInfo));
      window.location.href = getHostUrlPath(
        `/sync-redirect?schoolInfo=${schoolInfoStr}`,
        this.schoolInfo.serverUrl + '/manage'
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.login-button {
  margin-top: 64px !important;
}
</style>
