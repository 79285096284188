<template>
  <div class="login">
    <div class="login-header flex justify-between pd-t-32 pd-l-40 pd-r-32">
      <div class="flex items-center">
        <img class="login-logo mg-r-8" src="@images/components/login/logo.png" />
        <span class="login-title">{{ platformName }}</span>
      </div>
    </div>

    <div class="login-box relative">
      <!-- 账号登录 -->
      <accountLogin v-show="!showWechat" />
      <!-- 微信登录 -->
      <weixinLogin :visible="showWechat" ref="weixinRef" @read="showAgreement = true" />
      <!-- 切换及提示 -->
      <img
        class="login-type-change"
        src="@images/components/login/login_code.png"
        alt="切换"
        v-show="!showWechat"
        @click="changeToWeixinLogin"
      />
      <img
        class="login-type-change"
        src="@images/components/login/login_account.png"
        alt="切换"
        v-show="showWechat"
        @click="showWechat = false"
      />
      <div class="login-tip" v-show="tipVisible">
        {{ tipContent }}
        <div class="arrow"></div>
      </div>
    </div>

    <copyrightHtml />
    <!-- 用户协议 -->
    <agreement v-show="showAgreement" @back="showAgreement = false" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Button } from 'element-ui';
import accountLogin from './components/form/accountLogin';
import weixinLogin from './components/form/weixinLogin';
import agreement from './components/agreement/index';
import copyrightHtml from '@/components/copyrightHtml/index';

export default {
  components: {
    [Button.name]: Button,
    accountLogin,
    weixinLogin,
    copyrightHtml,
    agreement,
  },
  data() {
    return {
      showWechat: true,
      showAgreement: false,

      tipVisible: false,
      tipContent: '',
      timer: null,
    };
  },
  computed: {
    ...mapState({
      platformName: (state) => state.common.platformName,
    }),
  },
  watch: {
    showWechat() {
      this.handleLoginWayTip();
    },
  },
  methods: {
    // 显示升级结果
    showUpgradeResult(current_version) {
      let showUpgradeStatus = this.$store.state.upgrade.showUpgradeStatus;

      if (!showUpgradeStatus) return;

      let message = `重启成功，当前版本V${current_version}`;
      let type = 'success';

      if (showUpgradeStatus == 11) {
        message = '升级失败，已自动回滚至升级前版本。请下载升级日志查看失败原因';
        type = 'error';
      }

      this.$message({
        message,
        type,
        duration: 0,
        showClose: true,
      });

      // 进入后台管理页，弹窗显示升级结果信息
      this.$store.commit('upgrade/SET_CHECK_UPGRADE_RESULT', true);
      // clear
      this.$store.commit('upgrade/SET_SHOW_UPGRADE_STATUS', '');
    },

    changeToWeixinLogin() {
      this.showWechat = true;
      this.$refs.weixinRef.initQrCode();
    },

    // 提示登录方式
    handleLoginWayTip() {
      window.clearTimeout(this.timer);
      this.timer = null;
      this.tipContent = this.showWechat ? '账号登录' : '微信扫码登录';
      this.tipVisible = true;
      this.timer = setTimeout(() => (this.tipVisible = false), 3000);
    },
  },
  created() {
    // 清除浏览器记录，避免返回操作
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener(
        'popstate',
        () => {
          window.location.reload();
        },
        false
      );
    }
  },
  mounted() {
    window.entryTitle && (document.title = window.entryTitle + '-登录'); // 兼容双logo
    this.$store.dispatch('login/updateSchoolInfo');

    if (this.$route.query.wechatCode) {
      this.showWechat = true;
    }

    this.$nextTick(() => {
      this.handleLoginWayTip();
    });
  },
};
</script>

<style lang="scss" scoped src="./index.scss"></style>
