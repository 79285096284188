<template>
  <div class="protocal">
    <div class="p_head">
      <div class="back" @click="back">返回</div>
    </div>
    <div class="p_body">
      <iframe class="iframe" src="static/userAgreement.html" frameborder="0"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'agreement',
  methods: {
    back() {
      this.$emit('back');
    },
  },
  mounted() {
    const vm = this;
    document.querySelector('body').addEventListener('keydown', (e) => {
      var ev = e || window.event;
      var keyCode = ev.keyCode || ev.which;
      if (keyCode == 27) {
        vm.back();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.protocal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #efefef;
  font-family: 'MicrosoftYaHei';
  color: #333;
  z-index: 10;

  .p_head {
    height: 50px;
    background-color: #f2f2f2;
    border-bottom: 1px solid #ccc;

    .back {
      position: absolute;
      top: 17px;
      left: 32px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;

      &::before {
        margin-right: 5px;
        content: ' ';
        display: block;
        width: 7px;
        height: 7px;
        border-left: 2px solid #333;
        border-bottom: 2px solid #333;
        transform: rotate(45deg);
      }
    }
  }

  .p_body {
    width: 100%;
    height: calc(100% - 50px);
  }

  .iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
