<template>
  <div class="absolute login-box-bottom">
    <el-divider>其他账号登录</el-divider>
    <div class="flex justify-around pd-x-3">
      <el-tooltip v-for="item in otherPlatforms" :key="item.key" :content="item.name" placement="top" effect="light">
        <i :class="`${item.key}-logo`" @click="otherPlatformLogin(item.key)"></i>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { Divider, Tooltip } from 'element-ui';

export default {
  components: {
    [Divider.name]: Divider,
    [Tooltip.name]: Tooltip,
  },
  props: {
    readWarning: Boolean,
  },
  data() {
    return {
      otherPlatforms: [
        { key: 'sd', name: '山东中小学智慧教育平台' },
        { key: 'lc', name: '聊城智慧教育平台' },
        { key: 'anhui', name: '安徽基础教育资源应用平台登录' },
        { key: 'ningxia', name: '宁夏教育资源公共服务平台登录' },
        { key: 'gdae', name: '广东5G+智慧教育综合平台' },
        { key: 'ava', name: '全连接智慧教学平台' },
        { key: 'qxl', name: '青鹿管理平台' },
      ],
    };
  },
  methods: {
    // 其他平台登录
    otherPlatformLogin() {
      this.$message.info('功能待开发');
      // if (['sd', 'lc', 'anhui', 'ningxia', 'gdae'].includes(name)) {
      //   // 打开其他平台
      //   if (this.readWarning) {
      //     this.$message({
      //       message: '请勾选《用户协议及隐私政策》',
      //       type: 'warning',
      //     });
      //     return;
      //   }
      //   let href = `${window.$ctx}/OAuth2/${name}/web/authorize.do`;
      //   let aLink = document.createElement('a');
      //   aLink.href = href;
      //   aLink.rel = 'noreferrer';
      //   document.body.appendChild(aLink);
      //   aLink.click();
      //   document.body.removeChild(aLink);
      // } else {
      //   // 跳转到中转页
      //   this.$router.push({
      //     path: '/sync-redirect',
      //     query: {
      //       thirdProjectCode: name,
      //     },
      //   });
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
$logos: 'sd' 'lc' 'anhui' 'ningxia' 'ava' 'gdae' 'qxl';
.login-box-bottom {
  width: 340px;
  bottom: 28px;
  i {
    width: 40px;
    height: 24px;
    cursor: pointer;
    background-repeat: no-repeat;
    @each $i in $logos {
      &.#{$i}-logo {
        background-image: url('~@images/components/login/logo_#{$i}.png');
      }
    }
    &:hover {
      transform: scale(1.2);
    }
  }
}
.el-divider {
  @apply bg-A8;
}
::v-deep {
  .el-divider__text {
    @apply px-12px T6 text-F2;
  }
}
</style>
